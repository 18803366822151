.co-image {
  border-radius: .5em;
  max-height: 400px;
  max-width: 100%;
  margin: 0 auto;
  /* box-shadow: 0 30px 60px -30px rgb(0 0 0 / 30%), 0 30px 60px -30px rgb(0 0 0 / 25%); */
}

.co-image-news {
  /* border-radius: .5em; */
  /* max-height: 400px; */
  width: 100%;
  /* height: 150px; */
  aspect-ratio: 16 / 16;
  object-fit: contain;
  margin: 0 auto;
  display: block;
  /* margin-bottom: -89px; */
  /* margin-top: 16px; */
  /* box-shadow: 0 30px 60px -30px rgb(0 0 0 / 30%), 0 30px 60px -30px rgb(0 0 0 / 25%); */
}

.co-date{
color: #07A7F9;
/* font-family: "Segoe UI"; */
font-size: 20px;
font-style: normal;
font-weight: 60;
line-height: 20px;
}

.co-divider {
  width: 100%;
  margin: 0 auto !important;
}

.co-ftr__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  color: #042d43;
}

.co-ftr__link {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #1E75C6;
}

.co-label {
  font-size: 14px;
  color: #4f6c7b;
}

/* Page */
.co-page-heading {
  color: #03a9f4 !important;
  font-weight: 600px !important;
  font-size: 26px !important;
  line-height: 32px !important;
  /* font-weight: lighter; */
}

@media (max-width: 800px) {
  .co-page-heading {
    font-size: 26px !important;
  }
  .co-ftr__text {
    font-size: 16px !important;
  }
  .co-ftr__link {
    font-size: 16px !important;
  }
}

/* Customer Stories */
.co-cs-heading {
  color: #FFFFFF !important;
  /* font-family: Segoe UI; */
  font-weight: lighter;
}


.co-news-heading {
  color: #042D43 !important;
}

/* Menu */
.co-hdr-menu__root {
  padding: 0 16px;
  font-weight: 400 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  height: 100px;
  cursor: pointer;
}
.co-hdr-menu__root--mobile {
  padding: 8px 16px;
  font-weight: 400 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 180px;
  /* height: 72px; */
}

@media (max-width:1080px){
  .co-hdr-menu__root{
    font-size: 15px !important;
  }
}

.co-hdr-menu__root.hover,
.co-hdr-menu__root:hover,
.co-hdr-menu__root--mobile.hover,
.co-hdr-menu__root--mobile:hover  {
  background-color: #07A7F9;
}

.co-hdr-menu__child {
  padding: 12px 24px;
  font-size: 14px !important;
  font-weight: 400 !important;
  background-color: #042D43;
  border:.5px solid #07A6F8;
  color: #FFFFFF !important;
}

.co-hdr-menu__child:hover {
  color: #1976d2 !important;
  /* background-color:#042D43; */
}

/* MUI Overrides */
.MuiLink-root {
  font-weight: 500;
}

.imageContainer img{
  width: 100px;
}
.linkcolor{
  color:rgba(3, 169, 244);
  text-decoration: none;
  font-size: 20px;
}
.top30{
  margin-top: 50px;
}

.iframe{
  height: 300px;
  width: 100%;
  position:absolute;
  top:0;
  left:0;
  border: 1px black solid;
  background-color:  #F9F7F7;
}



html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.cliniops-bot {
  position: absolute;
  left: 1.7%;
  width: 48px;
  height: 48px;
  display:  flex;
  /* align-items: center; */
}
.cliniops-logo{
  width: 184px;
  height: 49px;

}


#webchat {
  /* position: fixed; */
  height: calc(100% - 66px);
  width: 100%;
  overflow: hidden;
}

#heading {
  position: relative;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66px;
  width: 100%;
}

h1 {
  font-size: 12px;
  /* font-family: Segoe UI; */
  font-style: normal;
  font-weight: 200;
  line-height: 20px;
  color: #f3f2f1;
  letter-spacing: 0.005em;
  display: table-cell;
  vertical-align: middle;
  padding: 13px 0px 0px 0px;
}

#chatwindow {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.webchat__basic-transcript__filler {
  background: rgba(249, 247, 247, 1);
}

.webchat__send-box__main {
  border-radius: 10px;
  margin: 10px;
  height: 60px;
}

.webchat__send-box-text-box__input {
  /* font-family: Roboto !important; */
  font-size: 14px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  background: rgba(0, 0, 0, 1);
  
}
.webchat__suggested-action__text{
  /* font-family: Roboto !important; */
  font-size: 14px !important;
}


.webchat__bubble__content{
  /* color: red; */
  /* font-family: Roboto; */
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
  border: 0;
}


#open-cliniops-bot{
  width: 70px;
  height: 70px;
  cursor: pointer;
  position: fixed;
  right: 1.5em;
  bottom: 1.5em;
  z-index: 98;
}

#bot-text-container{
  width: 85px;
  height: 40px;

  position: absolute;
  top: -32px;
  left: -60px;

  /* font-family: Inter; */
  font-size: 10px;
  font-weight: 100 !important;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: white;

  #text-background{
    width: 100%;
    height: 100%;
  }
    
  #bot-text{
    position: absolute;
    text-align: left;
    top: 10px;
    left: 7px;
    img{
      height: 15px;
      width: 70px;
    }

  }
}


#bot-icon{
    width: 72px;
    height: 72px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
    }
}


#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

#dialog-container{
  position: fixed;
  right: 42px;
  bottom: 42px;
}

#chatwindow-container{
  width: 400px;
  height: 550px;
  overflow: hidden;
  padding: 0;
}


/* media queries */
@media only screen and (max-width: 490px){
  body{
    font-size: 12px !important;
  }
  #dialog-container{
    /* border:  1px solid red; */
    width: 100vw;
    height: max(60vh, 490px);
    right: auto;
    bottom: 30px;
    box-sizing: border-box;
  }
  #chatwindow-container{
    /* border: 1px solid blue; */
    margin: auto;
    width: 90vw;
    height: 100%;
  }
 
  #cliniops-bot{
    width: 40px;
    height: 40px;
  }
  
  .cliniops-logo{
    width: 160px;
    height: 49px;
    /* border: 1px solid red; */
    img{
      width: 100%;
      height: 100%;
    }
  }
  
  #bot-icon{
    width: 60px;
    height: 60px;
    /* border: 1px solid red; */
  }
  
  #open-cliniops-bot{
    right: 0.5em;
    bottom: 0.5em;
  }

  .webchat__send-box__main {
    height: 45px;
  }
  
  .webchat__bubble__content{
    /* font-family: Roboto !important; */
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    border: 0;
  }

  .webchat__suggested-action__text{
    font-size: 12px !important;
  }

  .webchat__send-box-text-box__input{
    font-size: 12px !important;
  }

}

.co-image-product {
  border-radius: .5em;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: -30px;
  margin-top: 10px;
}

.co-image-connect {
  border-radius: 0em;
  max-width:100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
  /* box-shadow: 0 30px 60px -30px rgb(0 0 0 / 30%), 0 30px 60px -30px rgb(0 0 0 / 25%); */
}

.co-image-conduct {
  /* width: 250px; */
  display: block;     
    margin: auto;           
    max-width: 250px;        
    width: 100%;      
    height:auto;
}

/* Default styles (for mobile and smaller screens) */
.responsive-img {
  width: 22px;
  height: auto;
  object-fit: cover;
}

/* Media query for larger screens (desktop) */
@media (min-width: 768px) {
  .responsive-img {
    width: 50px;
  }
}

 @media (min-width: 900px) {
  /* Lock the width once we hit 900px or larger */
   .co-image-connect { 
    width: 530px; /* or any “locked” width you need */
    max-width: 100%;  
} 
 } 

/* .co-image-news {
  border-radius: 0em;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 16px;
} */

/* .slick-prev,
.slick-next {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  width: 0;
  height: 0;
} */

.slick-prev:before{
  content:"" !important;
}

.slick-next:before{
  content:"" !important;
}
.custom-slide .slick-list{
  overflow: visible !important;
}

.custom-dots li.slick-active div {
  background-color: #1E75C6 !important; /* Active color */
  border-color: #1E75C6 !important; /* Active border */
}

.custom-dots li div {
  background-color: #FFFFFF; /* Inactive color */
  border-color: #1E75C6; /* Inactive border */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}

.responsive-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
  font-size: 20px;
  color:#000000;
  font-weight: 400;
}
/* .newsroom-logos{
  width:150px,;
  height: 200px;
  border: 2px solid red;
  object-fit:contain;
} */

@media (min-width: 1200px) {
  .responsive-clamp {
    -webkit-line-clamp: 4; 
  }
}

@media (max-width: 1200px) and (min-width: 1100px) {
  .responsive-clamp {
    -webkit-line-clamp: 2; /* Show up to 2 lines */
  }
}


@media (max-width: 1100px) and (min-width: 900px) {
  .responsive-clamp {
    -webkit-line-clamp: 2; 
  }
}

.customerAvatar{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width:1050px) and (min-width:900px){
  .customerAvatar{
    width: 200px;
    margin: auto;
    height:100%;
    object-fit: contain;
  }
}

@media (max-width:400px){
  .customerAvatar{
    width: 200px;
    margin: auto;
    height:100%;
    object-fit: contain;
  }
}